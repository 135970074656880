import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import { grey } from '@mui/material/colors';
import { Link } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SettingsIcon from '@mui/icons-material/Settings';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Button from '@mui/material/Button';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import { TextField, Grid, Stack, Card, Accordion, AccordionSummary, AccordionDetails, FormControl, RadioGroup, FormControlLabel, Radio } from '@mui/material';
import Typography from '@mui/material/Typography';
import ProfitOrientatedPopover from '../components/Profit-OrientatedPopover';
import NonProfitPopover from '../components/Non-ProfitPopover';
import CharityOrientatedPopover from '../components/Charity-OrientatedPopover';
import Page from '../components/Page';

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        'linear-gradient( 95deg,rgb(255,193,7) 0%, rgb(216,165,22) 50%,rgb(214,116,0) 100%)',
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
      'linear-gradient( 95deg,rgb(255,193,7) 0%, rgb(216,165,22) 50%,rgb(214,116,0) 100%)',
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor:
      theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
    borderRadius: 1,
  },
}));

const ColorlibStepIconRoot = styled('div')(({ theme, ownerState }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
  zIndex: 1,
  color: '#fff',
  width: 40,
  height: 40,
  display: 'flex',
  borderRadius: '50%',
  justifyContent: 'center',
  alignItems: 'center',
  ...(ownerState.active && {
    backgroundImage:
      'linear-gradient( 136deg, rgb(255,193,7) 0%, rgb(252,200,42) 50%, rgb(214,160,0) 100%)',
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
  }),
  ...(ownerState.completed && {
    backgroundImage:
      'linear-gradient( 136deg, rgb(255,193,7) 0%, rgb(252,200,42) 50%, rgb(214,160,0) 100%)',
  }),
}));

function ColorlibStepIcon(props) {
  const { active, completed, className } = props;

  const icons = {
    1: <SettingsIcon />,
    2: <AddPhotoAlternateIcon />,
    3: <MonetizationOnIcon />,
    4: <RemoveRedEyeIcon />,
  };

  return (
    <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
      {icons[String(props.icon)]}
    </ColorlibStepIconRoot>
  );
}

ColorlibStepIcon.propTypes = {
  /**
   * Whether this step is active.
   * @default false
   */
  active: PropTypes.bool,
  className: PropTypes.string,
  /**
   * Mark the step as completed. Is passed to child components.
   * @default false
   */
  completed: PropTypes.bool,
  /**
   * The label displayed in the step icon.
   */
  icon: PropTypes.node,
};

const stepsMain = ['Account details', 'Profile picture', 'Monetization', 'Preview'];
const CssTextField = styled(TextField)({
  '& label.Mui-focused': {
    color: '#FFC107',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: '#FFC107',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: grey[500],
    },
    '&:hover fieldset': {
      borderColor: '#FFC107',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#FFC107',
    },
  },
});

const steps = [
  {
    label: 'Enter account name',
    description: <CssTextField size="small" id="custom-css-outlined-input" />,
  },
  {
    label: 'Interests & Skills',
    description: <CssTextField size="small" id="custom-css-outlined-input" />,
  },
  {
    label: 'Bio',
    description:  <CssTextField size="small" id="custom-css-outlined-input" />,
  },
];
const stepsPicture = [
  {
    label: 'Upload your photo',
    description: 'Choose your profile picture. You can always change it afterwards.',
  },
];
const stepsAccountTokenomics = [
  {
    label: 'Account tokenomics',
    description: 
    <FormControl sx={{ width: 250 }}>
      <RadioGroup
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group"
      >
        <FormControlLabel value="profit" control={<Radio sx={{ color: '#FFC107', mt: 0.6, mb: 0.6, '&.Mui-checked': { color: '#FFC107'} }} />} label={<ProfitOrientatedPopover />} />
        <FormControlLabel value="nonprofit" control={<Radio sx={{ color: '#FFC107', mt: 0.6, mb: 0.6, '&.Mui-checked': { color: '#FFC107'} }} />} label={<NonProfitPopover />} />
        <FormControlLabel value="charity" control={<Radio sx={{ color: '#FFC107', mt: 0.6, mb: 0.6, '&.Mui-checked': { color: '#FFC107'} }} />} label={<CharityOrientatedPopover />} />
      </RadioGroup>
    </FormControl>
  },
  {
    label: 'Purchase additional LIKE',
    description: 
    <Grid container sx={{ width: 300 }}>
    <Typography sx={{ my: 1 }}>5,000 LIKE will be distributed to each new account.</Typography>
    <Typography sx={{ my: 1, color: grey[500] }}>Initial balance: 5,000 LIKE + 0 LIkE</Typography>
    <Typography sx={{ my: 1 }}>1 LIKE = 0.001 USDC ($0.001)</Typography>
    <CssTextField size="small" label="Enter amount" id="custom-css-outlined-input" />
    </Grid>
  },
  {
    label: 'Checkout',
    description: 
    <Grid container sx={{ width: 300 }}>
      <Grid item xs={10}>
        <Typography sx={{ my: 1 }}>Account type: Charity</Typography>
      </Grid>
      <Grid item xs={10}>
        <Typography sx={{ my: 1 }}>Balance: 5,000 LIKE + 0 LIKE</Typography>
      </Grid>
      <Grid item xs={10}>
        <Typography sx={{ my: 1 }}>Sign-up Fee: 5 USDC</Typography>
      </Grid>
      <Grid item xs={10}>
        <Typography sx={{ mt: 1, mb: 2 }}>Total: 5 USDC</Typography>
      </Grid>
    </Grid>
  },
];


export default function VerticalLinearStepper() {
  const [activeStepMain, setActiveStepMain] = React.useState(0);
  const [activeStep, setActiveStep] = React.useState(0);
  const [activeStepPhoto, setActiveStepPhoto] = React.useState(0);
  const [activeStepAccountTokenomics, setActiveStepAccountTokenomics] = React.useState(0);

  const handleNextMain = () => {
    setActiveStepMain((prevActiveStepMain) => prevActiveStepMain + 1);
  };

  const handleBackMain = () => {
    setActiveStepMain((prevActiveStepMain) => prevActiveStepMain - 1);
  };

  const handleResetMain = () => {
    setActiveStepMain(0);
  };

  const handleNextAccountTokenomics = () => {
    setActiveStepAccountTokenomics((prevActiveStepAccountTokenomics) => prevActiveStepAccountTokenomics + 1);
  };
  const handleResetAccountTokenomics = () => {
    setActiveStepAccountTokenomics(0);
  };


  const handleBackAccountTokenomics = () => {
    setActiveStepAccountTokenomics((prevActiveStepAccountTokenomics) => prevActiveStepAccountTokenomics - 1);
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  return (
    <Page title="createaccount">
      <Grid container sx={{ display: 'flex', justifyContent: 'center' }}>
        <Typography variant="h2" sx={{ color: grey[700], mb: 5, mt: -7 }}>Create your account</Typography>
        <Stack sx={{ width: '100%' }} spacing={4}>
          <Stepper alternativeLabel activeStep={activeStepMain} connector={<ColorlibConnector />}>
            {stepsMain.map((label) => (
              <Step key={label}>
                <StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </Stack>
          {activeStepMain === 0 && (
        <Grid container xs={10} sx={{ display: 'flex', justifyContent: 'left' }}>
        <Typography variant="h2" sx={{ color: grey[800], mt: 5 }}>Account details</Typography>
      <Stepper sx={{ mt: 2, width: 250 }} activeStep={activeStep} orientation="vertical">
        {steps.map((step, index) => (
          <Step key={step.label} sx={{ '& .MuiStepLabel-root .Mui-active': { color: '#FFC107' }, '& .MuiStepLabel-root .Mui-completed': { color: '#FFC107' }}}>
            <StepLabel
              optional={
                index === 2 ? (
                  <Typography variant="caption">Last step</Typography>
                ) : null
              }
            >
              {step.label}
            </StepLabel>
            <StepContent>
              <Typography>{step.description}</Typography>
              <Box sx={{ mb: 2 }}>
                <div>
                  <Button
                    variant="contained"
                    onClick={handleNext}
                    sx={{ mt: 1, mr: 1, backgroundImage: 'linear-gradient(to bottom right, #FFC107, #FFD760, #FFE79C)' }}
                  >
                    {index === steps.length - 1 ? 'Finish' : 'Continue'}
                  </Button>
                  <Button
                    disabled={index === 0}
                    onClick={handleBack}
                    sx={{ mt: 1, mr: 1, backgroundColor: 'transparent', border: 0, color: grey[700] }}
                  >
                    Back
                  </Button>
                </div>
              </Box>
            </StepContent>
          </Step>
        ))}
      </Stepper>
      </Grid>
          )}
          {activeStepMain === 1 && (
        <Grid container xs={10} sx={{ display: 'flex', justifyContent: 'left' }}>
        <Typography variant="h2" sx={{ color: grey[800], mt: 5 }}>Upload photo</Typography>
        <Card sx={{ backgroundColor: 'transparent', height: 250, width: 250, boxShadow: 20, borderRadius: 50, mt: 5 }}>
          <Typography textAlign="center" sx={{ mt: 14.5 }}>Image preview</Typography>
        </Card>
      <Stepper sx={{ mt: 2, width: 250 }} activeStep={activeStepPhoto} orientation="vertical">
        {stepsPicture.map((step, index) => (
          <Step key={step.label} sx={{ '& .MuiStepLabel-root .Mui-active': { color: '#FFC107' }, '& .MuiStepLabel-root .Mui-completed': { color: '#FFC107' }}}>
            <StepLabel>
              {step.label}
            </StepLabel>
            <StepContent>
              <Typography>{step.description}</Typography>
              <Box sx={{ mb: 2 }}>
                <div>
                  <Button
                    variant="contained"
                    sx={{ mt: 1, mr: 1, backgroundImage: 'linear-gradient(to bottom right, #FFC107, #FFD760, #FFE79C)' }}
                  >
                    Upload
                  </Button>
                </div>
              </Box>
            </StepContent>
          </Step>
        ))}
      </Stepper>
      </Grid>
          )}
          {activeStepMain === 2 && (
        <Grid container xs={10} sx={{ display: 'flex', justifyContent: 'left' }}>
        <Typography variant="h2" sx={{ color: grey[800], mt: 5 }}>Payment & LIKE</Typography>
        <Card sx={{ backgroundColor: 'transparent', width: 360, boxShadow: 0, borderRadius: 0, mt: 5 }}>
          <Accordion sx={{ backgroundImage: 'linear-gradient(to bottom right, #FFC107, #FFD760, #FFE79C)' }} style={{ borderRadius: 15 }}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon sx={{ color: '#FFFFFF' }} />}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
              <Typography sx={{ color: '#FFFFFF', ml: 2 }} variant="h5" textAlign="center">
                Why do I need LIKE?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography textAlign="left" sx={{ color: '#FFFFFF' }}>
                Each interaction such as 'like', 'comment', 'bookmark' and 'follow' costs 1 LIKE or more. By doing any of these interactions,
                users choose the amount of LIKE tokens to send to the content creator. By supporting the content creator, users who have high
                volume of interactions can also earn a proportion of the future cotent-creators' revenue. The minimum amount is 1 LIKE and 
                there is no maximum amount of LIKE that user may donate to the content creator.
              </Typography>
            </AccordionDetails>
          </Accordion>
        </Card>
      <Stepper sx={{ mt: 2, width: 250 }} activeStep={activeStepAccountTokenomics} orientation="vertical">
        {stepsAccountTokenomics.map((step, index) => (
          <Step key={step.label} sx={{ '& .MuiStepLabel-root .Mui-active': { color: '#FFC107' }, '& .MuiStepLabel-root .Mui-completed': { color: '#FFC107' }}}>
            <StepLabel>
              {step.label}
            </StepLabel>
            <StepContent>
              <Typography>{step.description}</Typography>
              <Box sx={{ mb: 2 }}>
                <div>
                  <Button
                    variant="contained"
                    onClick={handleNextAccountTokenomics}
                    sx={{ mt: 1, mr: 1, backgroundImage: 'linear-gradient(to bottom right, #FFC107, #FFD760, #FFE79C)' }}
                  >
                    {index === stepsAccountTokenomics.length - 1 ? 'Finish' : 'Next'}
                  </Button>
                  <Button
                    disabled={index === 0}
                    onClick={handleBackAccountTokenomics}
                    sx={{ mt: 1, mr: 1, backgroundColor: 'transparent', border: 0, color: grey[700] }}
                  >
                    Back
                  </Button>
                </div>
              </Box>
            </StepContent>
          </Step>
        ))}
      </Stepper>
      </Grid>
          )}
          {activeStepMain === 3 && (
        <Grid container xs={10} sx={{ display: 'flex', justifyContent: 'left' }}>
        <Typography variant="h2" sx={{ color: grey[800], mt: 5, pl: 1 }}>Account preview</Typography>
      <Grid sx={{ borderRadius: 5, backgroundColor: grey[100], boxShadow: 10, mt: 3, height: 300, width: '100%' }}>
        <Grid container item xs={12} sx={{ backgroundColor: 'transparent', mt: 3, display: 'flex', justifyContent: 'left' }}>
          <Grid item xs={5} sx={{ ml: 2, mt: 1 }}>
            <Card sx={{ width: 150, height: 150, borderRadius: 50, border: 1 }} />
          </Grid>
          <Grid item xs={6}>
            <Typography sx={{ ml: 2 }}>Your bio will appear here. You may type anything you want but some points that you may elaborate a bit more on are your hobbies and interests.</Typography>
          </Grid>
          <Grid item xs={10}>
            <Typography sx={{ ml: 2, mt: 2 }}>Account type: Non-Profit</Typography>
          </Grid>
          <Grid item xs={11} sx={{ my: 1 }}>
            <Typography sx={{ ml: 2 }}>Skills & Interests: Photographer, Influencer and designer.</Typography>
          </Grid>
        </Grid>    
        </Grid>
      </Grid>
          )}
      {activeStepMain > 0 && (
        <Grid container xs={10} sx={{ display: 'flex', justifyContent: 'center' }}>
          <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', mb: 3 }}>
            {activeStepMain < 3 && (
          <Button onClick={handleNextMain} sx={{ mr: 1, border: 0, backgroundColor: 'transparent', color: '#FFC107' }}>
            Next
          </Button>
            )}
            {activeStepMain > 2 && (
          <Button component={Link} to="/account" onClick={handleNextMain} sx={{ mr: 1, border: 0, backgroundColor: 'transparent', color: '#FFC107' }}>
            Create
          </Button>
            )}
          <Button onClick={() => {
            handleBackMain();
            handleReset();
            handleResetAccountTokenomics();
            }}
            sx={{ mr: 1, border: 0, backgroundColor: 'transparent', color: grey[800] }}>
            Back
          </Button>
          {activeStepMain === 3 && (
            <Button onClick={() => {
              handleResetMain();
              handleReset();
              handleResetAccountTokenomics();
              }}
              sx={{ mr: 1, border: 0, backgroundColor: 'transparent', color: grey[800] }}>
              Reset
            </Button>
          )}
          </Grid>
        </Grid>
      )}
      {activeStepMain === 0 && (
        <Grid container xs={10} sx={{ display: 'flex', justifyContent: 'center' }}>
          {activeStep === steps.length && (
          <Typography sx={{ mt: 4 }}>Account details have been updated.</Typography>
          )}
          {activeStep === steps.length && (
          <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', mb: 3, mt: 2 }}>
          <Button size="large" onClick={handleNextMain} sx={{ mr: 1, border: 0, backgroundColor: 'transparent', color: '#FFC107' }}>
            Next
          </Button>
          <Button size="large" onClick={() => {
            handleBackMain();
            handleReset();
            }}
            sx={{ mr: 1, border: 0, backgroundColor: 'transparent', color: grey[800] }}>
            Back
          </Button>
          </Grid>
          )}
        </Grid>
      )}
    </Grid>
    </Page>
  );
}
