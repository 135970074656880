import { useEffect, useState } from 'react';
import { useWeb3React } from '@web3-react/core';
import { injected } from './connectors';

function MetamaskProvider({ children }) {
    const { active: networkActive, error: networkError, activate: activateNetwork } = useWeb3React();
    const [loaded, setLoaded] = useState(false);
    useEffect(() => {
        injected
            .isAuthorized()
            .then((isAuthorized) => {
                setLoaded(true);
                if (isAuthorized && !networkActive && !networkError) {
                    console.log('Metamask detected. Please find the connect button on the website.');
                }
            })
            .catch(() => {
                setLoaded(true);
            });
    }, [activateNetwork, networkActive, networkError]);
    if (loaded) {
        return children;
    }
    return (
        <>
        </>
    );
}

export default MetamaskProvider;
