// scroll bar
import 'simplebar/src/simplebar.css';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import Web3 from "web3/dist/web3.min";
import { Web3ReactProvider } from '@web3-react/core';
import MetamaskProvider from './MetamaskProvider';
//
import App from './App';
// ----------------------------------------------------------------------

function getLibrary(provider) {
  return new Web3(provider);
}

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <Web3ReactProvider getLibrary={getLibrary}>
    <HelmetProvider>
        <BrowserRouter>
          <MetamaskProvider>
            <App />
          </MetamaskProvider>
        </BrowserRouter>
    </HelmetProvider>
    </Web3ReactProvider>
);

