// @mui
import { useTheme, styled, alpha } from '@mui/material/styles';
import React from 'react';
import { Grid, Typography, Button, AppBar, MenuItem, Divider, Menu } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import ArchiveIcon from '@mui/icons-material/Archive';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import TuneIcon from '@mui/icons-material/Tune';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import { FaFistRaised } from 'react-icons/fa';
import SettingsIcon from '@mui/icons-material/Settings';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ThumbUpAltOutlinedIcon from '@mui/icons-material/ThumbUpAltOutlined';
import PublicOutlinedIcon from '@mui/icons-material/PublicOutlined';
import { grey } from '@mui/material/colors';
// components
import Page from '../components/Page';
// sections

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[200],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
    },
  },
}));

export default function Account() {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };
  return (
    <Page title="Account">
      <Grid container item lg={12} sx={{ display: 'flex', justifyContent: 'center' }}>
      <AppBar position="fixed" sx={{ top: 0, bottom: 'auto', backgroundColor: '#FFFFFF', height: 50 }}>
        <Grid item xs={11.5} sx={{ display: 'flex', justifyContent: 'right' }}>
          <Button onClick={handleClick} sx={{ backgroundColor: 'transparent', border: 0, color: '#FFC107' }} endIcon={<SettingsIcon style={{ fontSize: 25 }} />}>
            AccountName
          </Button>
            <StyledMenu
              id="demo-customized-menu"
              MenuListProps={{
                'aria-labelledby': 'demo-customized-button',
              }}
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
            >
              <MenuItem onClick={handleClose} disableRipple>
                <EditIcon />
                My courses/services
              </MenuItem>
              <MenuItem onClick={handleClose} disableRipple>
                <FileCopyIcon />
                  Details & Stats
              </MenuItem>
              <Divider sx={{ my: 0.5 }} />
              <MenuItem onClick={handleClose} disableRipple>
                <ArchiveIcon />
                  Purchased services/courses
              </MenuItem>
              <MenuItem onClick={handleClose} disableRipple>
                <MoreHorizIcon />
                  Log out
              </MenuItem>
            </StyledMenu>
        </Grid>
      </AppBar>
        <Grid container sx={{ display: 'flex', justifyContent: 'center' }}>
          <Grid container item xs={11} sx={{ display: 'flex', justifyContent: 'center', backgroundColor: grey[200], borderRadius: 1, boxShadow: 5 }}>
            <Grid item xs={3} sx={{ mt: 1 }}>
              <AccountCircleOutlinedIcon sx={{ fontSize: 110, color: grey[800] }} />
            </Grid>
            <Grid item xs={8} sx={{ mt: 1.5, ml: 2 }}>
              <Typography variant="h7" textAlign="left" sx={{ color: grey[800], fontSize: 14 }}>
                You can put any information in regards to your profile here. Some key points and words that you should elaborate on are in relation to your content, skills and future.
              </Typography>
            </Grid>
            <Grid item xs={3.5} sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
              <Typography>
                Followers
              </Typography>
            </Grid>
            <Grid item xs={3} sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
              <Typography>
                Following
              </Typography>
            </Grid>
            <Grid item xs={3.5} sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
              <Typography>
                Likes
              </Typography>
            </Grid>
            <Grid item xs={3.5} sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
              <Typography>
                1,280
              </Typography>
            </Grid>
            <Grid item xs={3} sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
              <Typography>
                3,204
              </Typography>
            </Grid>
            <Grid item xs={3.5} sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
              <Typography>
                94,520
              </Typography>
            </Grid>
          </Grid>
        </Grid>
          <Grid item xs={3} sx={{ display: 'flex', justifyContent: 'center', mt: 3, borderBottom: 1, borderRadius: 0.5, borderColor: '#FFC107' }}>
            <Button sx={{ width: '100%', '&:hover': { backgroundColor: '#FFE79C' } }}>
              <FaFistRaised size={25} style={{ color: grey[800] }} />
            </Button>
          </Grid>
          <Grid item xs={3} sx={{ display: 'flex', justifyContent: 'center', mt: 3, borderBottom: 1, borderRadius: 0.5, borderColor: '#FFC107' }}>
            <Button sx={{ width: '100%', '&:hover': { backgroundColor: '#FFE79C' } }}>
              <AssignmentOutlinedIcon sx={{ fontSize: 30, color: grey[800] }} />
            </Button>
          </Grid>
          <Grid item xs={3} sx={{ display: 'flex', justifyContent: 'center', mt: 3, borderBottom: 1, borderRadius: 0.5, borderColor: '#FFC107' }}>
            <Button sx={{ width: '100%', '&:hover': { backgroundColor: '#FFE79C' } }}>
              <PublicOutlinedIcon sx={{ fontSize: 30, color: grey[800] }} />
            </Button>
          </Grid>
          <Grid item xs={3} sx={{ display: 'flex', justifyContent: 'center', mt: 3, borderBottom: 1, borderRadius: 0.5, borderColor: '#FFC107' }}>
            <Button sx={{ width: '100%', '&:hover': { backgroundColor: '#FFE79C' } }}>
              <InfoOutlinedIcon sx={{ fontSize: 30, color: grey[800] }} />
            </Button>
          </Grid>
          <Grid item xs={8} sx={{ mt: 5.5, ml: 2, display: 'flex', justifyContent: 'center' }}>
            <Typography variant="h7" textAlign="center" sx={{ color: grey[800], fontSize: 14 }}>
              No content has been uploaded to your profile yet.
            </Typography>
          </Grid>
          <Grid item xs={8} sx={{ mt: 2, ml: 2, display: 'flex', justifyContent: 'center' }}>
            <Button sx={{ color: '#FFFFFF', width: 100, borderRadius: 1, backgroundImage: 'linear-gradient(to bottom right, #FFC107, #FFD760, #FFE79C)' }}>
              Upload
            </Button>
          </Grid>
      </Grid>
    </Page>
  );
}
