import { useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
//
import DashboardApp from './pages/DashboardApp';
import Account from './pages/Account';
import Market from './pages/Market';
import CreateOrLogIn from './pages/CreateOrLogIn';
import CreateAccount from './pages/CreateAccount';
import CreateTextPost from './pages/CreateTextPost';
import CreatePicturePost from './pages/CreatePicturePost';
import CreateVideoPost from './pages/CreateVideoPost';
import RegisterAccount from './pages/RegisterAccount';

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    {
      path: '/',
      element: <DashboardLayout />,
      children: [
        { path: '', element: <DashboardApp /> },
      ],
    },
    {
      path: '/account',
      element: <DashboardLayout />,
      children: [
        { path: '', element: <Account /> },
      ],
    },
    {
      path: '/market',
      element: <DashboardLayout />,
      children: [
        { path: '', element: <Market /> },
      ],
    },
    {
      path: '/createtextpost',
      element: <DashboardLayout />,
      children: [
        { path: '', element: <CreateTextPost /> },
      ],
    },
    {
      path: '/createimagepost',
      element: <DashboardLayout />,
      children: [
        { path: '', element: <CreatePicturePost /> },
      ],
    },
    {
      path: '/createvideopost',
      element: <DashboardLayout />,
      children: [
        { path: '', element: <CreateVideoPost /> },
      ],
    },
    {
      path: '/createaccount',
      element: <DashboardLayout />,
      children: [
        { path: '', element: <CreateAccount /> },
      ],
    },
    {
      path: '/login',
      element: <DashboardLayout />,
      children: [
        { path: '', element: <CreateOrLogIn /> },
      ],
    },
  ]);
}
