import PropTypes from 'prop-types';
// material
import { styled, alpha, useTheme } from '@mui/material/styles';
import { Link } from 'react-router-dom';
import { Box, AppBar, Toolbar, Grid, IconButton, Menu, MenuItem, Divider, Typography } from '@mui/material';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import VideoCallIcon from '@mui/icons-material/VideoCall';
import ExploreOutlinedIcon from '@mui/icons-material/ExploreOutlined';
import TextsmsIcon from '@mui/icons-material/Textsms';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import StorefrontOutlinedIcon from '@mui/icons-material/StorefrontOutlined';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import LiveTvIcon from '@mui/icons-material/LiveTv';
import { grey } from '@mui/material/colors';
import * as React from 'react';

// ----------------------------------------------------------------------
const APPBAR_MOBILE = 64;
const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 20,
    marginTop: theme.spacing(-5),
    minWidth: 80,
    minHiehgt: 80,
    color:
      theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        marginRight: theme.spacing(1),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
    },
  },
}));

const RootStyle = styled(AppBar)(({ theme }) => ({
  boxShadow: 'none',
  position: 'fixed',
  top: 'auto',
  bottom: 0,
  width: 'fullwidth',
  height: 70,
  backdropFilter: 'blur(6px)',
  WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
  backgroundColor: 'white',
  [theme.breakpoints.up('lg')]: {
    width: 'fullwidth',
  },
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  minHeight: APPBAR_MOBILE,
  [theme.breakpoints.up('lg')]: {
    padding: theme.spacing(0, 5),
  },
}));

// ----------------------------------------------------------------------

DashboardNavbar.propTypes = {
  onOpenSidebar: PropTypes.func,
};

export default function DashboardNavbar() {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <RootStyle>
      <ToolbarStyle sx={{ borderTop: 1, borderColor: '#FFE79C' }}>
        <Grid width="100%" sx={{ display: 'flex', justifyContent: 'center' }}>
          <IconButton component={Link} to='/' sx={{ width: 70, '&:hover': { backgroundColor: 'transparent' } }}>
            <ExploreOutlinedIcon sx={{ color: grey[900], fontSize: 30, '&:hover': { color: '#FFC107' } }}/>
          </IconButton>
          <Box sx={{ flexGrow: 0.22 }} />
          <IconButton sx={{ width: 70, '&:hover': { backgroundColor: 'transparent' } }}>
            <LiveTvIcon sx={{ color: grey[900], fontSize: 30, '&:hover': { color: '#FFC107' } }}/>
          </IconButton>
          <Box sx={{ flexGrow: 0.22 }} />
          <IconButton sx={{ '&:hover': { backgroundColor: 'transparent' } }}>
            <AddCircleIcon
            id="demo-customized-button"
        aria-controls={open ? 'demo-customized-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        variant="contained"
        disableElevation
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon sx={{ color: '#FFC107' }}/>}
        sx={{ color: '#FFC107', '&:hover': { backgroundColor: 'transparent' }, fontSize: 40, width: 55, backgroundColor: 'transparent', boxShadow: 0, my: 1 }} />
          </IconButton>
          <Box sx={{ flexGrow: 0.22 }} />
          <IconButton component={Link} to='/market' sx={{ width: 70, '&:hover': { backgroundColor: 'transparent' } }}>
            <StorefrontOutlinedIcon sx={{ color: grey[900], fontSize: 30, '&:hover': { color: '#FFC107' } }}/>
          </IconButton>
          <Box sx={{ flexGrow: 0.22 }} />
          <IconButton component={Link} to='/login' sx={{ width: 70, '&:hover': { backgroundColor: 'transparent' } }}>
            <AccountCircleOutlinedIcon sx={{ color: grey[900], fontSize: 30, '&:hover': { color: '#FFC107' } }}/>
          </IconButton>

      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem component={Link} to='/createtextpost' onClick={handleClose} disableRipple sx={{ justifyContent: 'center' }}>
          <TextsmsIcon sx={{ fontSize: 30, mt: 0.5 }} />
        </MenuItem>
        <MenuItem component={Link} to='/createimagepost' onClick={handleClose} disableRipple sx={{ justifyContent: 'center' }}>
          <AddAPhotoIcon sx={{ fontSize: 30, mb: 0.5 }} />
        </MenuItem>
        <MenuItem component={Link} to='/createvideopost' onClick={handleClose} disableRipple sx={{ justifyContent: 'center' }}>
          <VideoCallIcon sx={{ fontSize: 37, ml: 0.45 }} />
        </MenuItem>
      </StyledMenu>
        </Grid>
      </ToolbarStyle>
    </RootStyle>
  );
}
