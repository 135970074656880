// @mui
import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import { Grid, Typography, Button, TextField, IconButton, Box } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { FaFistRaised } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { grey } from '@mui/material/colors';
import Page from '../components/Page';
// components

const CssTextField = styled(TextField)({
  '& label.Mui-focused': {
    color: '#FFC107',
  },
  '& .MuiInput-underline:after': {
    border: 0,
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      border: 0,
    },
    '&:hover fieldset': {
      border: 0,
    },
    '&.Mui-focused fieldset': {
      border: 0,
    },
  },
});


export default function CreateVideoPost() {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };
    return(
<Page title="CreateTextPost">
    <Grid container item lg={12} xs={12} sm={12} md={12} sx={{ display: 'flex', justifyContent: 'left', top: 10, pb: 1, borderBottom: 1, borderColor: '#FFC107', position: 'fixed', left: 0, backgroundImage: 'linear-gradient(to bottom, #FFFFFF, #FFFFFF, #F9F9F9)' }}>
      <IconButton component={Link} to="/">
        <CloseIcon sx={{ color: grey[700], fontSize: 35, '&:hover': { color: '#FFC107' } }} />
      </IconButton>
    </Grid>
    <Grid container item lg={12} xs={12} sm={12} md={12} sx={{ display: 'flex', justifyContent: 'center', top: 20, position: 'fixed' }}>
      <Typography variant="h6" sx={{ color: grey[800], fontWeight: 50 }}>Upload video</Typography>
    </Grid>
    <Grid container item lg={12} xs={12} sm={12} md={12} sx={{ display: 'flex', justifyContent: 'right', top: 15, right: 15, position: 'fixed', backgroundColor: 'transparent' }}>
      <Button sx={{ backgroundImage: 'linear-gradient(to bottom right, #FFC107, #FFD760, #FFE79C)', boxShadow: 10, border: 0, borderRadius: 1, width: 65, height: 40, color: '#FFFFFF' }}>
        Post
      </Button>
    </Grid>
    <Grid container lg={12} xs={12} sm={12} md={12} sx={{ display: 'flex', justifyContent: 'left', pl: 1.5 }}>
      <Grid container xs="auto" sx={{ pt: 0.4 }}>
        <IconButton sx={{ border: 1, height: 50, width: 50 }}>AC</IconButton>
      </Grid>
      <Grid container xs={9}>
        <Typography variant="h5" sx={{ pl: 2 }}>Account Name</Typography>
        <Typography variant="h5" sx={{ pl: 2, fontWeight: 40 }}>Account type: Non-Profit</Typography>
      </Grid>
    </Grid>
    <Grid container lg={12} xs={12} sm={12} md={12} sx={{ display: 'flex', justifyContent: 'center' }}>
      <CssTextField fullWidth multiline maxRows={5} minRows={5} placeholder="Express your thoughts to the world..." />
    </Grid>
    <Grid container lg={12} xs={12} sm={12} md={12} sx={{ display: 'flex', justifyContent: 'center' }}>
      <Button sx={{ backgroundImage: 'linear-gradient(to bottom right, #FFC107, #FFD760, #FFE79C)', boxShadow: 10, border: 0, borderRadius: 1, width: 130, height: 40, color: '#FFFFFF' }}>
        Upload Video
      </Button>
    </Grid>
</Page>
    );
}