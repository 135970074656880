// @mui
import * as React from 'react';
import { styled, alpha, useTheme } from '@mui/material/styles';
import Menu from '@mui/material/Menu';
import InfoIcon from '@mui/icons-material/Info';
import MenuItem from '@mui/material/MenuItem';
import EditIcon from '@mui/icons-material/Edit';
import Divider from '@mui/material/Divider';
import ArchiveIcon from '@mui/icons-material/Archive';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import TuneIcon from '@mui/icons-material/Tune';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import SearchIcon from '@mui/icons-material/Search';
import Select from '@mui/material/Select';
import ListItemText from '@mui/material/ListItemText';
import InputBase from '@mui/material/InputBase';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Grid, Typography, Button, Accordion, AccordionDetails, AccordionSummary, Checkbox, AppBar, IconButton, Rating } from '@mui/material';
import { grey } from '@mui/material/colors';
import JSCourse from '../images/JScourse.png';
// components
import Page from '../components/Page';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const names = [
  'Marketing',
  'Photo Editting',
  'Video Editting',
  'Blendr',
  'Blockchain Development',
  'Smart Contracts',
  'Website Development',
  'Servers & hosting',
  'Clouds & drives',
  'Hardware',
  'Mobile Application Development',
  'Photoshop',
  'HTML',
  'C++',
  'PHP',
  'Solidity',
  'JavaScript',
  'C#',
  'ERC20/BEP20',
  'Photography',
  '3D Modelling',
];

const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: '#ffffff',
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: 'auto',
    },
  }));

  const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }));
  
  const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)})`,
      transition: theme.transitions.create('width'),
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        width: '12ch',
        '&:focus': {
          width: '20ch',
        },
      },
    },
  }));

const StyledMenu = styled((props) => (
    <Menu
      elevation={0}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      {...props}
    />
  ))(({ theme }) => ({
    '& .MuiPaper-root': {
      borderRadius: 6,
      marginTop: theme.spacing(1),
      minWidth: 180,
      color:
        theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[200],
      boxShadow:
        'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
      '& .MuiMenu-list': {
        padding: '4px 0',
      },
      '& .MuiMenuItem-root': {
        '& .MuiSvgIcon-root': {
          fontSize: 18,
          color: theme.palette.text.secondary,
          marginRight: theme.spacing(1.5),
        },
        '&:active': {
          backgroundColor: 'transparent',
        },
      },
    },
  }));

export default function Market() {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };
    const [personName, setPersonName] = React.useState([]);
    const [value, setValue] = React.useState(2);
    const handleChange = (event) => {
      const {
        target: { value },
      } = event;
      setPersonName(
        // On autofill we get a stringified value.
        typeof value === 'string' ? value.split(',') : value,
      );
    };
  return (
    <Page title="Market">
      <AppBar position="fixed" sx={{ top: 0, bottom: 'auto', backgroundColor: '#FFFFFF', boxShadow: 5 }}>
          <Grid xs={12} sx={{ display: 'flex', justifyContent: 'left', backgroundColor: '#ffffff', position: 'fixed', left: 0 }}>
            <Search>
              <SearchIconWrapper>
                <SearchIcon sx={{ color: '#FFC107' }} />
              </SearchIconWrapper>
              <StyledInputBase
                sx={{ mt: 0.6, color: grey[500] }}
                placeholder="Search for a course/service/nfts…"
                inputProps={{ 'aria-label': 'search' }}
              />
            </Search>
          </Grid>
          <Grid item xs={12} sx={{ pr: 2, display: 'flex', justifyContent: 'right', backgroundColor: '#FFFFFF' }}>
            <Button
              id="demo-customized-button"
              aria-controls={open ? 'demo-customized-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              variant="contained"
              disableElevation
              onClick={handleClick}
              sx={{ color: '#ffffff', '&:hover': { backgroundColor: '#FFC107' }, width: 50, backgroundImage: 'linear-gradient(to bottom right, #FFC107, #FFD760, #FFE79C)', boxShadow: 0, my: 1 }}
            >
              <KeyboardArrowDownIcon sx={{ color: '#FFFFFF' }} />
            </Button>
            <StyledMenu
              id="demo-customized-menu"
              MenuListProps={{
                'aria-labelledby': 'demo-customized-button',
              }}
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
            >
              <MenuItem onClick={handleClose} disableRipple>
                <EditIcon />
                My courses/services
              </MenuItem>
              <MenuItem onClick={handleClose} disableRipple>
                <FileCopyIcon />
                  Details & Stats
              </MenuItem>
              <Divider sx={{ my: 0.5 }} />
              <MenuItem onClick={handleClose} disableRipple>
                <ArchiveIcon />
                  Purchased services/courses
              </MenuItem>
              <MenuItem onClick={handleClose} disableRipple>
                <MoreHorizIcon />
                  Log out
              </MenuItem>
            </StyledMenu>
          </Grid>
      </AppBar>
    <Grid item xs={10} sx={{ display: 'flex', justifyContent: 'center' }}>
        <Typography textAlign="center" sx={{ mt: 2, mb: 5, fontSize: 26, color: '#FFC107' }}>
          LIKE
        </Typography>
        <Typography textAlign="center" sx={{ mt: 2, mb: 5, fontSize: 26, ml: 1, color: grey[600] }}>
          METAMARKET
        </Typography>
    </Grid>
    <Grid item sx={{ display: 'flex', justifyContent: 'center' }}>
      <Typography textAlign="center" sx={{ mb: 2, color: '#FFC107', fontSize: 26 }}>
        Learn, teach, exlpore, share and influence like never before.
      </Typography>
    </Grid>
    <Grid container item xs={12} sx={{ display: 'flex', justifyContent: 'center', mt: 5 }}>
      <Grid container item xs={10} sx={{ display: 'flex', justifyContent: 'center' }}>
        <Accordion sx={{ backgroundImage: 'linear-gradient(to bottom right, #FFC107, #FFD760, #FFE79C)' }} style={{ borderRadius: 15 }}>
          <AccordionSummary
              expandIcon={<ExpandMoreIcon sx={{ color: '#FFFFFF' }} />}
              aria-controls="panel1a-content"
              id="panel1a-header"
          >
            <Typography sx={{ color: '#FFFFFF', ml: 2 }} variant="h5" textAlign="center">
              Overview
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography textAlign="center" sx={{ color: '#FFFFFF' }}>
              LIKE Market is a decentralized marketplace which allows users to offer their skills in the form of a video course,
              tutorial or any kind of online services while tokenizing the whole process without the need of a middle-man.
              No additional fees will be aplied and users are in complete control of their offers & profits.
            </Typography>
          </AccordionDetails>
        </Accordion>
      </Grid>
    </Grid>
      <Grid item xs={9.5} sx={{ display: 'flex', justifyContent: 'left', pl: 5, mt: 5 }}>
        <Typography sx={{ color: grey[600], fontWeight: 500 }}>Product type:</Typography>
      </Grid>
      <Grid container item xs={4.5} sx={{ display: 'flex', justifyContent: 'left', pl: 5 }}>
      <FormControl>
        <RadioGroup
          row
          aria-labelledby="demo-row-radio-buttons-group-label"
          name="row-radio-buttons-group"
        >
          <FormControlLabel value="courses" control={<Radio sx={{ color: '#FFC107', mt: 0.6, mb: 0.6, '&.Mui-checked': { color: '#FFC107'} }} />} label={<Typography sx={{ color: grey[700] }}>Course</Typography>} />
          <FormControlLabel value="services" control={<Radio sx={{ color: '#FFC107', mt: 0.6, mb: 0.6, '&.Mui-checked': { color: '#FFC107'} }} />} label={<Typography sx={{ color: grey[700] }}>Service</Typography>} />
          <FormControlLabel value="nfts" control={<Radio sx={{ color: '#FFC107', mt: 0.6, mb: 0.6, '&.Mui-checked': { color: '#FFC107'} }} />} label={<Typography sx={{ color: grey[700] }}>Ownership</Typography>} />
        </RadioGroup>
      </FormControl>
    </Grid>
    <Grid container item xs={12} sx={{ display: 'flex', justifyContent: 'center', mt: 5 }}>
      <Grid item xs={9.5} sx={{ display: 'flex', justifyContent: 'left' }}>
        <Typography sx={{ color: grey[600], fontWeight: 500 }}>Category:</Typography>
      </Grid>
    </Grid>
    <Grid container item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
      <Grid item xs={10} sx={{ display: 'flex', justifyContent: 'left' }}>
      <FormControl sx={{ width: '100%', border: 0 }}>
        <Select
          labelId="demo-multiple-checkbox-label"
          id="demo-multiple-checkbox"
          multiple
          size="small"
          sx={{ borderRadius: 1.7, border: 1, borderColor: '#FFC107' }}
          value={personName}
          onChange={handleChange}
          renderValue={(selected) => selected.join(', ')}
          MenuProps={MenuProps}
        >
          {names.map((name) => (
            <MenuItem key={name} value={name}>
              <Checkbox sx={{ color: '#FFC107', '&.Mui-checked': { color: '#FFC107' } }} checked={personName.indexOf(name) > -1} />
              <ListItemText primary={name} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      </Grid>
    </Grid>
    <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}>
      <Button sx={{ width: 100, border: 1, borderRadius: 1.5, color: grey[700], mx: 1, '&:hover': { backgroundColor: 'transparent', borderColor: '#FFC107', color: '#FFC107' } }}>
        <SearchIcon sx={{ fontSize: 30, '&:hover': { color: '#FFC107' } }} />Search
      </Button>
      <IconButton sx={{ width: 70, mx: 1, '&:hover': { backgroundColor: 'transparent' } }}>
        <TuneIcon sx={{ color: grey[700], fontSize: 30, '&:hover': { color: '#FFC107' } }}/>
      </IconButton>
    </Grid>
    <Grid item xs={9} sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
        <Typography> Page: 1 of 1</Typography>
    </Grid>
  <Grid container item xs={12} sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
  <Grid container item xs={10.5} sx={{ display: 'flex', justifyContent: 'center', border: 1, borderRadius: 2, borderColor: '#FFC107' }}>
    <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', border: 1, borderRadius: 2, borderColor: '#FFC107' }}>
        <img src={JSCourse} height="100%" width="100%" style={{ borderRadius: 16 }} alt="course" />
    </Grid>
    <Grid container item xs={11.5} sx={{ display: 'flex', justifyContent: 'left', mt: 1 }}>
    <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'left', mt: 1, ml: 1 }}>
        <Typography>Author:</Typography>
        <Typography sx={{ textDecoration: 'underline', pl: 0.5, fontWeight: 505 }}>AccountName</Typography>
    </Grid>
    <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'left', mt: 1, ml: 1 }}>
        <Typography>Product type: Course</Typography>
    </Grid>
    <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'left', mt: 1, ml: 1 }}>
        <Typography>Category: JavaScript</Typography>
    </Grid>
    <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'left', mt: 1, ml: 1 }}>
        <Typography>Length: 2 hours</Typography>
    </Grid>
    <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'left', mt: 1, borderBottom: 1, ml: 1, borderColor: '#FFC107' }}>
        <Typography>Price: 20 BUSD</Typography>
    </Grid>
    </Grid>
    <Grid item xs={11} sx={{ display: 'flex', justifyContent: 'left', mt: 3 }}>
    <Typography variant="h5">JavaScript Course for beginners - part 1</Typography>
    </Grid>
    <Grid item xs={11} sx={{ display: 'flex', justifyContent: 'left', mb: 2, mt: 1 }}>
        <Typography textAlign="left">JavaScript Tutorial for newbies. Get in touch with the basics of JavaScript and complete the whole program of 15 tutorials in order to gain enough skills for further self-development processes.</Typography>
    </Grid>
    <Grid container item xs={11} sx={{ display: 'flex', justifyContent: 'left', mt: 1 }}>
      <Grid item xs={7} sx={{ display: 'flex', justifyContent: 'left' }}>
        <Typography textAlign="left" sx={{ my: 0.5, fontWeight: 510 }}>Purchased: 2,048</Typography>
      </Grid>
      <Grid item xs={7} sx={{ display: 'flex', justifyContent: 'left', py: 1 }}>
        <Button sx={{ backgroundColor: 'transparent', border: 1, borderColor: grey[800], borderRadius: 1, color: grey[800] }} endIcon={<InfoIcon sx={{ color: grey[800] }} />} >
        Feedback
        </Button>
      </Grid>
      <Grid item xs={11} sx={{ display: 'flex', justifyContent: 'left' }}>
        <Typography textAlign="left" sx={{ my: 0.5 }}>Rating: 3.8/5</Typography>
        <Rating
        sx={{ pl: 2, pt: 0.3 }}
        name="simple-controlled"
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
        }}
      />
      </Grid>
    </Grid>
    <Grid item xs={11} sx={{ display: 'flex', justifyContent: 'left', mb: 3, mt: 3 }}>
      <Button sx={{ width: 100, border: 1, color: grey[700], borderRadius: 1.2, mx: 1, '&:hover': { backgroundColor: 'transparent', borderColor: '#FFC107', color: '#FFC107' } }}>
        Purchase
      </Button>
      <Button sx={{ width: 100, color: grey[700], mx: 1, '&:hover': { backgroundColor: 'transparent', color: '#FFC107' } }}>
        Details
      </Button>
    </Grid>
  </Grid>
  </Grid>
    <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
        <Typography> Page: 1 of 1</Typography>
    </Grid>
  </Page>
  );
}
