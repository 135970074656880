// @mui
import { styled, alpha, useTheme } from '@mui/material/styles';
import React from 'react';
import { Grid, Typography, Avatar, IconButton, AppBar, Card, FormControl, Divider, Button, MenuItem, Select, InputLabel, Menu, Icon } from '@mui/material';
import BookmarkAddIcon from '@mui/icons-material/BookmarkAdd';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import ArchiveIcon from '@mui/icons-material/Archive';
import TextsmsIcon from '@mui/icons-material/Textsms';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import VideoCallIcon from '@mui/icons-material/VideoCall';
import CommentIcon from '@mui/icons-material/Comment';
import SendIcon from '@mui/icons-material/Send';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import { FaFistRaised } from 'react-icons/fa';
import { grey } from '@mui/material/colors';
// components
import Page from '../components/Page';
// sections

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: '#ffffff',
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: 150,
  [theme.breakpoints.up('sm')]: {
    width: 'auto',
  },
}));

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    backgroundColor: 'transparent',
    color:
      theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[200],
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: '#FFFFFF',
      },
      '&:active': {
        backgroundColor: 'transparent',
      },
    },
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: 70,
  },
}));

export default function DashboardApp() {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [age, setAge] = React.useState('');
  const handleChange = (event) => {
    setAge(event.target.value);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <Page title="Explore">
      <Grid container sx={{ top: 0, bottom: 0, right: 0, left: 0, position: 'fixed', justifyContent: 'center', backgroundColor: 'black' }}>
    <AppBar position="fixed" sx={{ top: 0, bottom: 'auto', backgroundColor: 'transparent', height: 53, boxShadow: 0 }}>
      <Grid sx={{ display: 'flex', justifyContent: 'left', position: 'fixed', top: 0 }}>
        <IconButton onClick={handleClick}>
          <MoreHorizIcon sx={{ color: '#FFFFFF', fontSize: 35 }} />
        </IconButton>
        <StyledMenu
              id="demo-customized-menu"
              MenuListProps={{
                'aria-labelledby': 'demo-customized-button',
              }}
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
            >
              <MenuItem onClick={handleClose} disableRipple>
                <TextsmsIcon sx={{ color: '#FFFFFF' }} />
              </MenuItem>
              <MenuItem onClick={handleClose} disableRipple>
                  <AddAPhotoIcon sx={{ color: '#FFFFFF' }} />
              </MenuItem>
              <MenuItem onClick={handleClose} disableRipple>
                  <VideoCallIcon sx={{ color: '#FFFFFF' }} />
              </MenuItem>
            </StyledMenu>
      </Grid>
      <Grid sx={{ display: 'flex', justifyContent: 'center' }}>
        <Typography variant="h6" sx={{ color: 'white', mt: 1.5, pr: 2 }}>Following</Typography>
        <Typography variant="h6" sx={{ color: 'white', mt: 1.5 }}>|</Typography>
        <Typography variant="h6" sx={{ color: 'white', mt: 1.5, pl: 2 }}>Explore</Typography>
      </Grid>
      <Grid sx={{ right: 0, position: 'fixed' }}>
            <SearchIcon sx={{ color: 'white', fontSize: 30, mt: 1.5, mr: 2 }} />
      </Grid>
    </AppBar>
        <Grid container item lg={12} sx={{ display: 'flex', justifyContent: 'right', bottom: 395, position: 'fixed', pr: 1 }}>
          <Avatar sx={{ backgroundColor: 'white', color: 'black', height: 45, width: 45 }}>AC</Avatar>
          <Card sx={{ backgroundColor: '#FFC107', position: 'absolute', bottom: -10, mr: 1.55, height: 20, borderRadius: 10 }}>
            <AddOutlinedIcon sx={{ color: 'white', fontSize: 20 }} />
          </Card>
        </Grid>
        <Grid container item xs={1.6} sx={{ display: 'flex', justifyContent: 'center', bottom: 313, right: 0, position: 'fixed', pr: 0.2 }}>
          <IconButton sx={{ '&:hover': { backgroundColor: 'transparent' } }}>
            <FaFistRaised size={32} style={{ color: '#FFC107' }} />
          </IconButton>
        </Grid>
        <Grid container xs={1.6} sx={{ right: 0, bottom: 290, justifyContent: 'center', position: 'fixed', pr: 0.1 }}>
          <Typography sx={{ color: 'white' }}>0</Typography>
        </Grid>
        <Grid container xs={1.6} sx={{ right: 0, bottom: 230, justifyContent: 'center', position: 'fixed', pr: 0.3, display: 'flex' }}>
            <IconButton sx={{ '&:hover': { backgroundColor: 'transparent' } }}>
              <CommentIcon sx={{ color: 'white', '&:hover': { color: '#FFC107' }, fontSize: 29 }} />
            </IconButton>
        </Grid>
        <Grid container xs={1.6} sx={{ right: 0, bottom: 215, justifyContent: 'center', position: 'fixed', pr: 0.3 }}>
          <Typography sx={{ color: 'white' }}>0</Typography>
        </Grid>
        <Grid container item xs={1.6} sx={{ display: 'flex', justifyContent: 'center', bottom: 150, right: 0, position: 'fixed', pr: 0.5 }}>
          <IconButton sx={{ '&:hover': { backgroundColor: 'transparent' } }}>
            <BookmarkAddIcon sx={{ color: 'white', '&:hover': { color: '#FFC107' }, fontSize: 40 }} />
          </IconButton>
        </Grid>
        <Grid container item xs={1.6} sx={{ display: 'flex', justifyContent: 'center', bottom: 85, right: 0, position: 'fixed', pr: 0.5 }}>
          <IconButton sx={{ '&:hover': { backgroundColor: 'transparent' } }}>
            <SendIcon sx={{ color: 'white', '&:hover': { color: '#FFC107' }, fontSize: 28 }} />
          </IconButton>
        </Grid>
        <Grid item lg={12} xs={10} sx={{ left: 0, bottom: 150, position: 'fixed', ml: 2 }}>
              <Typography color="white" sx={{ fontWeight: 60, fontSize: 22 }}>username</Typography>
        </Grid>
        <Grid item lg={12} xs={10} sx={{ left: 0, bottom: 115, position: 'fixed', ml: 2 }}>
              <Typography color="white">Video description & hashtags</Typography>
        </Grid>
        </Grid>
    </Page>
  );
}
