// @mui
import { useTheme } from '@mui/material/styles';
import React from 'react';
import { Grid, Typography, Button } from '@mui/material';
import { grey } from '@mui/material/colors';
import { useWeb3React } from '@web3-react/core';
import { Link } from 'react-router-dom';
import LogoMain from '../images/Logo.png';
import { injected } from '../connectors';
// components
import Page from '../components/Page';
// sections

export default function CreateOrLogIn() {
  const theme = useTheme();
  const { activate } = useWeb3React();
    const connect = async () => {
        try {
            await activate(injected);
        } catch (ex) {
            console.log(ex);
        }
    };
  return (
    <Page title="Account">
      <Grid container item lg={12} sx={{ display: 'flex', justifyContent: 'center' }}>
        <Grid item sx={{ display: 'flex', justifyContent: 'center', mt: -5, ml: 3 }}>
            <img src={LogoMain} height={300} alt="logo" />
        </Grid>
        <Grid item xs={10} sx={{ display: 'flex', backgroundColor: grey[200], borderRadius: 1, border: 0, justifyContent: 'center', mt: 2 }}>
            <Typography textAlign="center" sx={{ my: 1, fontStyle: 'italic' }}>“I disapprove of what you say, but I will defend to the death your right to say it.” </Typography>
        </Grid>
        <Grid item lg={3} md={3} xs={3} sm={3} sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}>
            <Button component={Link} to="/createaccount" sx={{ color: 'white', backgroundColor: '#FFC106', '&:hover': { backgroundColor: '#FFE39C', color: '#FFC107' } }}>Log In</Button>
        </Grid>
        <Grid item xs={10} sx={{ display: 'flex', bottom: 0, justifyContent: 'center' }}>
            <Typography textAlign="center" sx={{ my: 1, fontWeight: 500, color: grey[500] }}>You own your data. Freedom of speech.</Typography>
        </Grid>
       </Grid>
    </Page>
  );
}
